
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import HoldOn from '@/provider/HoldOn.vue'
import { defineComponent } from 'vue'
import { StudyType } from 'happychart/types'

export default defineComponent({
  name: 'SignalList',
  components: { HoldOn, AsyncNullableList },
  setup () {
    return {
      list: [
        {
          titleI18nPath: 'membership_18',
          title: 'Trend Pro',
          profileI18nPath: 'membership_19',
          profile: 'See the ups and downs of the trend and overbought/oversold areas intuitively',
          banner: '/img/membership/cover_pro.jpg',
          id: 2,
          type: StudyType.TRENDPRO,
          elite: true,
        },
      ],
    }
  },
})

