
import { defaultToArray } from '@/common/common'
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import Icon from '@/components/Icon.vue'
import TheTournamentItem from '@/modules/membership/tournament/components/TheTournamentItem.vue'
import {
  readTournamentList,
  TournamentSchema,
} from '@/modules/membership/tournament/tournament.api'
import Holder from '@/provider/Holder.vue'
import { YesOrNo } from '@/types'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TournamentList',
  components: { Icon, TheTournamentItem, Holder, AsyncNullableList },
  setup () {
    const tournament = shallowRef<TournamentSchema[] | null>(null)

    readTournamentList({ status: YesOrNo.YES, page: 1, pageCount: 50 })
      .then(resp => tournament.value = defaultToArray(resp) as Array<TournamentSchema>)

    return {
      tournament,
    }
  },
})
