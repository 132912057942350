
import Icon from '@/components/Icon.vue'
import TheVBlock from '@/pages/prime/components/TheVBlock.vue'
import RedPointAlert from '@/provider/RedPointAlert.vue'
import state from '@/state'
import { YesOrNo } from '@/types'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'TheHeader',
  components: { RedPointAlert, Icon, TheVBlock },
  setup () {
    const subscribed = computed(() => state.prime)
    const renew = computed(() => subscribed.value.renew === YesOrNo.YES)

    return {
      subscribed,
      renew,
    }
  },
})
