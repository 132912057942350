
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import TheLiveItem from '@/modules/membership/components/TheLiveItem.vue'
import { LiveRoom, readLiveList } from '@/modules/membership/member.api'
import HoldOn from '@/provider/HoldOn.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'LiveList',
  components: { HoldOn, TheLiveItem, AsyncNullableList },
  setup () {
    const list = shallowRef<Array<LiveRoom>>([])

    readLiveList().then(resp => {
      list.value = resp || []
    })

    return {
      list,
    }
  },
})
