
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import HoldOn from '@/provider/HoldOn.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SignalList',
  components: { HoldOn, AsyncNullableList },
  setup () {
    return {
      list: [
        {
          profileI18nPath: 'membership_33',
          profile: 'Exclusive trading mode. Earn +100% profit in minutes',
          banner: '/img/prime/quick_play@2x.png',
        },
      ],
    }
  },
})

